<template>
  <div class="container">
    <div class="personal__title title">Личный кабинет</div>
    <div class="personal-links">
      <router-link
        class="personal-link"
        v-for="(item, index) in personalMenu"
        :key="index"
        :to="{ path: item.path, query: { category: item.picture } }"
      >
        <span class="personal-link__icon">
          <!-- <div class="personal-link__icon-notification"></div> -->
          <svg class="icon" :class="`icon-${item.picture}`">
            <use :xlink:href="`#${item.picture}`"></use>
          </svg>
        </span>
        <span class="personal-link__text">
          {{ item.title }}
        </span>
      </router-link>
    </div>

    <div class="personal-logout">
      <div
        class="personal-link personal-link--logout"
        @click.prevent="logout()"
      >
        <span class="personal-link__icon personal-link__icon--grey">
          <svg class="icon" :class="`icon-${personalLogout.picture}`">
            <use :xlink:href="`#${personalLogout.picture}`"></use>
          </svg>
        </span>
        <span class="personal-link__text">
          {{ personalLogout.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store.getters.isAuthenticated ? next() : next("/login");
  },

  computed: {
    personalMenu() {
      return this.$store.state.menu.personal.menu;
    },
    personalLogout() {
      return this.$store.state.menu.personal.logout;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("AUTH_LOGOUT");

      this.$store.dispatch("FIRST_LOAD_INFORMATION").then(() => {
        this.$store.dispatch("HOME_FETCH_DATA").then(() => {
          this.$router.push("/");
        });
      });
    },
  },
};
</script>
